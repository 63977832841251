<template>
	<section class="about col" align-x="left">
		<quote-title label="關於金枝" align-self="center" />
		<br>
		<small>電腦版</small>
		<el-card>
			<div class="row" gutter="10">
				<div>
					<upload v-model="banners" media-folder="about" :limit="1" />
				</div>
				<div class="row" align-self="bottom">
					<el-button @click="handleSave('banners', banners)" type="success" size="mini">儲存</el-button>
					<el-button @click="handleCancel('banners')" type="info" size="mini">取消</el-button>
				</div>
			</div>
		</el-card>
		<br>
		<small>手機版</small>
		<el-card>
			<div class="row" gutter="10">
				<div>
					<upload v-model="banners_mobile" media-folder="about" :limit="1" />
				</div>
				<div class="row" align-self="bottom">
					<el-button @click="handleSave('banners_mobile', banners_mobile)" type="success" size="mini">儲存</el-button>
					<el-button @click="handleCancel('banners_mobile')" type="info" size="mini">取消</el-button>
				</div>
			</div>
		</el-card>
		<br>
		<el-card>
			<tinymce v-model="article" />
			<div class="row" align-x="right">
				<el-button @click="handleSave('article', article)" type="success">儲存</el-button>
				<el-button @click="handleCancel('article')" type="info">取消</el-button>
			</div>
		</el-card>
		<br>
	</section>
</template>

<script>
import { mapActions, mapState } from "vuex"
export default {
	components: {
		Upload: () => import("@/components/upload"),
		Tinymce: () => import("@/components/tinymce")
	},
	data() {
		return {
			banners: new Array,
			banners_mobile: new Array,
			article: new String
		}
	},
	computed: {
		...mapState("about", ["_about"]),
	},
	methods: {
		...mapActions("about", ["_getData", "_updateData"]),
		async handleSave(key, update_data) {
			let data = new Object();
			data[key] = update_data;
			this.$message.success(await this._updateData({ db: "about", data }));
		},
		handleCancel(key) {
			if (this.$refs["ruleForm"] && this.$refs["ruleForm"].length) this.$refs["ruleForm"].forEach(form => form.resetFields());
			this.$set(this.$data, key, this._.cloneDeep(this._about[key]));
		},
	},
	async created() {
		await this._getData("about");
		this._.assign(this.$data, this._.cloneDeep(this._about));
	}
}
</script>